/* eslint-disable import/prefer-default-export */

export const ethnicityOptions = [
  {value: 1, label: "White"},
  {value: 2, label: "Mixed"},
  {value: 3, label: "Indian/Other Asian"},
  {value: 4, label: "Pakistani/Bangladeshi"},
  {value: 5, label: "Black"},
  {value: 6, label: "Other Ethnic Origins"},
];

export const ageBandOptions = [
  {value: 1, label: "<16"},
  {value: 2, label: "16 and 17"},
  {value: 3, label: "18 and 19"},
  {value: 4, label: "20 to 24"},
  {value: 5, label: "25 to 34"},
  {value: 6, label: "35 to 44"},
  {value: 7, label: "45 to 54"},
  {value: 8, label: "55 to 66"},
  {value: 9, label: "67 and over"},
];

export const genderOptions = [
  {value: 1, label: "Males"},
  {value: 2, label: "Females"},
];

export const nationalityOptions = [
  {value: 1, label: "UK"},
  {value: 2, label: "Non-UK"},
];

export const religionOptions = [
  {value: 1, label: "Christian (all denominations)"},
  {value: 2, label: "Muslim"},
  {value: 3, label: "Other religions"},
  {value: 4, label: "No religion/Not stated"},
];

export const healthOptions = [
  {value: 1, label: "Full health"},
  {value: 2, label: "Full health, Only Asthma"},
  {value: 3, label: "Full health, Other health probs and/or disability"},
  {value: 4, label: "Medium health"},
  {value: 5, label: "Medium health, Only Asthma"},
  {value: 6, label: "Medium health, Other health probs and/or disability"},
  {value: 7, label: "Poor health"},
];

export const qualificationOptions = [
  {value: 1, label: "Higher education"},
  {value: 2, label: "A-levels or equivalent"},
  {value: 3, label: "GCSEs or equivalent"},
  {value: 4, label: "Other/no qualifications"},
];

export const maxLinesOptions = [
  {value: 0, label: "All"},
  {value: 1, label: "1"},
  {value: 2, label: "2"},
  {value: 3, label: "3"},
  {value: 4, label: "4"},
  {value: 5, label: "5"},
  {value: 6, label: "6"},
  {value: 7, label: "7"},
  {value: 8, label: "8"},
  {value: 9, label: "9"},
  {value: 10, label: "10"},
];
