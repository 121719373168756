/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from "react";
import { navigate } from 'gatsby';
import {
  ethnicityOptions,
  ageBandOptions,
  genderOptions,
  nationalityOptions,
  religionOptions,
  healthOptions,
  qualificationOptions,
} from "./filterOptions";
import { MultiSelect } from "../../MultiSelect";
import Message from "../message";
import Alert from "../alert";
import { safelyCallJSFunction } from "../visualisation-functions";

import Switch, {SwitchProps} from "../../deliberative-survey/Switch";

declare const window: any;
const isBrowser = typeof window !== "undefined";


const FilterPanel: React.FunctionComponent = () => {
  const [ethnicityValues, setEthnicityValues] = useState(ethnicityOptions);
  const [ageBandValues, setAgeBandValues] = useState(ageBandOptions);
  const [genderValues, setGenderValues] = useState(genderOptions);
  const [nationalityValues, setNationalityValues] = useState(nationalityOptions);
  const [religionValues, setReligionValues] = useState(religionOptions);
  const [healthValues, setHealthValues] = useState(healthOptions);
  const [qualificationValues, setQualificationValues] = useState(qualificationOptions);
  const [chartDisplayed, setChartDisplayed] = useState(false);
  const [clearAlertOpen, setClearAlertOpen] = useState(false);
  const [deleteLineAlertOpen, setDeleteLineAlertOpen] = useState(false);

  const [selectedView, setSelectedView] = useState('one');

  useEffect(() => {
    if (selectedView === 'four') {
      navigate('/modelling/visualisation-four', {replace:true});
    }
  }, [selectedView]);

  const viewSwitch:SwitchProps = {
    conf: {
      options: [
        {
          text: 'National',
          val: 'one'
        },
        {
          text: 'Regional',
          val: 'four'
        }
      ],
      setSelectedOption: setSelectedView,
      selectedOption: selectedView
    }
  }

  const submitButtonPressed = () => {
    const { aleph } = window;
    if (aleph) {
      aleph.selectionIndexes.ethnicities = ethnicityValues.map((ethnicityValue) => ethnicityValue.value);
      aleph.selectionIndexes.genders = genderValues.map((genderValue) => genderValue.value);
      aleph.selectionIndexes.health = healthValues.map((healthValue) => healthValue.value);
      aleph.selectionIndexes.ageBands = ageBandValues.map((ageBandValue) => ageBandValue.value);
      aleph.selectionIndexes.religions = religionValues.map((religionValue) => religionValue.value);
      aleph.selectionIndexes.qualifications = qualificationValues.map((qualificationValue) => qualificationValue.value);
      aleph.selectionIndexes.nationalities = nationalityValues.map((nationalityValue) => nationalityValue.value);
      safelyCallJSFunction("submitLineSelection");
      setChartDisplayed(true);
    }
  }

  const clearButtonPressed = () => {
    setClearAlertOpen(true);
  }

  const clearButtonConfirmed = () => {
    safelyCallJSFunction("handleLineChartClearWarning", { id: "ok" });
    setChartDisplayed(false);
  }

  const selectAllButtonPressed = () => {
    setEthnicityValues(ethnicityOptions);
    setAgeBandValues(ageBandOptions);
    setGenderValues(genderOptions);
    setNationalityValues(nationalityOptions);
    setReligionValues(religionOptions);
    setHealthValues(healthOptions);
    setQualificationValues(qualificationOptions);
  }

  const deselectAllButtonPressed = () => {
    setEthnicityValues([]);
    setAgeBandValues([]);
    setGenderValues([]);
    setNationalityValues([]);
    setReligionValues([]);
    setHealthValues([]);
    setQualificationValues([]);
  }

  const anyFiltersEmpty = () => (
    ethnicityValues.length === 0 ||
    genderValues.length === 0 ||
    healthValues.length === 0 ||
    ageBandValues.length === 0 ||
    religionValues.length === 0 ||
    qualificationValues.length === 0 ||
    nationalityValues.length === 0
  )

  const generateWarningMessage = () => {
    const missingFilters = [];
    if (ethnicityValues.length === 0) { missingFilters.push("Ethnicities") };
    if (genderValues.length === 0) { missingFilters.push("Genders") };
    if (healthValues.length === 0) { missingFilters.push("Health Statuses") };
    if (ageBandValues.length === 0) { missingFilters.push("Age Bands") };
    if (religionValues.length === 0) { missingFilters.push("Religions") };
    if (qualificationValues.length === 0) { missingFilters.push("Qualifications") };
    if (nationalityValues.length === 0) { missingFilters.push("Nationalities") };
    return missingFilters.join(", ");
  }

  const areAllFiltersFull = () => (
    ethnicityValues.length === ethnicityOptions.length &&
    genderValues.length === genderOptions.length &&
    healthValues.length === healthOptions.length &&
    ageBandValues.length === ageBandOptions.length &&
    religionValues.length === religionOptions.length &&
    qualificationValues.length === qualificationOptions.length &&
    nationalityValues.length === nationalityOptions.length
  )

  const areAllFiltersEmpty = () => (
    ethnicityValues.length === 0 &&
    genderValues.length === 0 &&
    healthValues.length === 0 &&
    ageBandValues.length === 0 &&
    religionValues.length === 0 &&
    qualificationValues.length === 0 &&
    nationalityValues.length === 0
  )

  if (isBrowser) {
    window.showDeleteLineAlert = () => {
      setDeleteLineAlertOpen(true);
    }
  }

  return (
    <section className="px-4 py-4 mx-auto md:px-8 md:py-4 z-50">
      <Alert open={clearAlertOpen} setOpen={setClearAlertOpen} title="Clear chart?" message="Are you sure you want to clear the chart?  This action cannot be undone." cancelText="Cancel" actionText="Clear" onAction={() => { clearButtonConfirmed(); }} />
      <Alert open={deleteLineAlertOpen} setOpen={setDeleteLineAlertOpen} title="Delete line?" message="Are you sure you want to delete this line?" cancelText="Cancel" actionText="Delete" onAction={() => { safelyCallJSFunction("handleLineChartLineDeletionWarning", { id: "ok" }) }} />
      <div className="grid grid-cols-3 lg:grid-cols-4 2xl:grid-cols-7 gap-2">
        <MultiSelect className="z-50" placeholder="Ethnicity" options={ethnicityOptions} value={ethnicityValues} onChange={setEthnicityValues} />
        <MultiSelect className="z-50" placeholder="Age Band" options={ageBandOptions} value={ageBandValues} onChange={setAgeBandValues} />
        <MultiSelect className="z-50" placeholder="Sex" options={genderOptions} value={genderValues} onChange={setGenderValues} />
        <MultiSelect className="z-50" placeholder="Nationality" options={nationalityOptions} value={nationalityValues} onChange={setNationalityValues} />
        <MultiSelect className="z-50" placeholder="Religion" options={religionOptions} value={religionValues} onChange={setReligionValues} />
        <MultiSelect className="z-50" placeholder="Health" options={healthOptions} value={healthValues} onChange={setHealthValues} />
        <MultiSelect className="z-50" placeholder="Qualification" options={qualificationOptions} value={qualificationValues} onChange={setQualificationValues} />
      </div>
      <div className="flex justify-center mt-4">
        <button
          type="button"
          onClick={() => { selectAllButtonPressed(); }}
          disabled={areAllFiltersFull()}
          className="w-64 h-6 mx-4 inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 disabled:bg-gray-400 disabled:cursor-not-allowed hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="flex-1">Select All</span>
        </button>
        <button
          type="button"
          onClick={() => { deselectAllButtonPressed(); }}
          disabled={areAllFiltersEmpty()}
          className="w-64 h-6 mx-4 inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 disabled:bg-gray-400 disabled:cursor-not-allowed hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="flex-1">Deselect All</span>
        </button>
        <button
          type="button"
          onClick={() => { clearButtonPressed(); }}
          disabled={!chartDisplayed}
          className="w-64 h-6 mx-4 inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 disabled:bg-gray-400 disabled:cursor-not-allowed hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
        >
          <span className="flex-1">Clear Chart</span>
        </button>
        <button
          type="button"
          onClick={() => { submitButtonPressed(); }}
          disabled={anyFiltersEmpty()}
          className="w-64 h-6 mx-4 inline-flex items-center my-1 px-2 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-secondary-600 disabled:bg-gray-400 disabled:cursor-not-allowed hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
        >
          <span className="flex-1">Submit</span>
        </button>
        <Switch conf={viewSwitch.conf}/>
      </div>
      <div className={`mt-4 ${anyFiltersEmpty() ? "" : "hidden"}`}>
        <Message type="warning" title="Please complete these selection lists" message={generateWarningMessage()} />
      </div>
    </section>
  )
}

export default FilterPanel;
