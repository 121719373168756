/* eslint-disable import/prefer-default-export */
export const visualisationSettings = {
  "rootPath": "/",
  "renderFunction": "onload",
  "chartName": "aleph-chart aleph-line-chart",
  "scripts": [
    {"file": "/js/jQuery-v3.6.0.js"},
    {"file": "/js/jquery-ui.js"},
    {"file": "/js/d3.v5.min.js"},
    {"file": "/visualisationScripts/population-line-chart/js/aleph-lineChart-alephConstructObject-script.js"},
    {"file": "/visualisationScripts/population-line-chart/js/aleph-lineChart-windowResizeFunction-script.js"},
    {"file": "/visualisationScripts/population-line-chart/js/aleph-lineChart-projectLoadFunctions-script.js"},
    {"file": "/visualisationScripts/population-line-chart/js/aleph-lineChart-script.js"},
    {"file": "/visualisationScripts/population-line-chart/js/aleph-lineChart-genericProjectFunctions-script.js"},
  ],
  "css": [
    { "file": "/visualisationScripts/population-line-chart/css/aleph-futureForces-lineChart-style.css" },
    { "file": "/css/aleph-global-colour-palettes.css" },
    { "file": "/css/dstl-global-colour-palettes.css" },
    { "file": "/css/font-awesome.min.css" },
    { "file": "/css/github.min.css" },
    { "file": "/css/jquery-ui.css" },
  ]
}
