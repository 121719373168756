/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */

import React from "react";
import withRoleRequired from '../../lib/withRoleRequired';
import { visualisationSettings } from "../../components/visualisations/visualisation-one/visualisationSettings";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import SideBar from "../../components/visualisations/sidebar";
import FilterPanel from "../../components/visualisations/visualisation-one/filterPanel";
import Visualisation from "../../components/visualisations/visualisation";

const LineViz: React.FunctionComponent = () => (
  <Layout
    pageHeader=""
    breadcrumbs={[{ label: 'Workforce Population Modelling', link: '/modelling' }]}
    moreInfo="modelling/visualisation-one"
  >
    <SEO
      keywords={[`dstl`, `future`, `workforce`, `modelling`, `line`, `visualisation`]}
      title="Population Projections"
    />
  
    { /* For anything tablet or bigger */}
    <div className="hidden md:block">
      <SideBar currentChart={1} />
      <FilterPanel />
      <div className="aleph-chart-container aleph-line-chart-container">
        <div className="line-chart-slider-range-container">
          <div id="vertical-line-slider-range">
            <label className="slider-label vertical-slider-label slider-label-fixed-top" />
            <label className="slider-label vertical-slider-label slider-label-movable-top" />
            <label className="slider-label vertical-slider-label slider-label-movable-bottom" />
            <label className="slider-label vertical-slider-label slider-label-fixed-bottom" />
          </div>
        </div>
  
        <div className="line-chart-horizontal-slider-range-container">
          <div id="horizontal-line-slider-range">
            <label className="slider-label slider-label-fixed-left" />
            <label className="slider-label horizontal-slider-label slider-label-movable-left" />
            <label className="slider-label horizontal-slider-label slider-label-movable-right" />
            <label className="slider-label slider-label-fixed-right" />
          </div>
        </div>
  
        <Visualisation {...visualisationSettings} params={{}} />
      </div>
    </div>
  
    { /* For phones */}
    <div className="md:hidden">
      <img alt="Line Graph" src="/image/visualisations/line.svg" />
      <div className="m-4 text-center">
          Visualisations cannot be viewed at this screen size
      </div>
    </div>
  
  
  </Layout>
)

export default withRoleRequired(LineViz);
